import React from "react";

export default ({ color }) => (
  <svg width="30" height="23" viewBox="0 0 40 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 3H20.2692H33.5385" stroke="#333333" stroke-width="3.3" stroke-linecap="round" />
    <path d="M2.33301 11.332H37.7176" stroke="#333333" stroke-width="3.3" stroke-linecap="round" />
    <path d="M7 20H33.5385" stroke="#333333" stroke-width="3.3" stroke-linecap="round" />
  </svg>

);
